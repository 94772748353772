/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {
	
    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

    // Pin #top-bar-menu on scroll
    if (Foundation.MediaQuery.atLeast('large')) {
        jQuery(window).scroll(function () {
            let content = '.off-canvas-content';
            let header = 'header';
            if (jQuery(this).scrollTop() > "200") {
                jQuery(header).addClass('pinned');
                jQuery(content).css('padding-top', jQuery(header).height());
            } else {
                jQuery(header).removeClass('pinned');
                jQuery(content).css('padding-top', '0px');
            }
        });
    }

    // Add ability to change SVG image fills
    jQuery('img.svg').each(function(){
        let jQueryimg = jQuery(this);
        let imgID = jQueryimg.attr('id');
        let imgClass = jQueryimg.attr('class');
        let imgURL = jQueryimg.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            let jQuerysvg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                jQuerysvg = jQuerysvg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                jQuerysvg = jQuerysvg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            jQuerysvg = jQuerysvg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!jQuerysvg.attr('viewBox') && jQuerysvg.attr('height') && jQuerysvg.attr('width')) {
                jQuerysvg.attr('viewBox', '0 0 ' + jQuerysvg.attr('height') + ' ' + jQuerysvg.attr('width'));
            }

            // Replace image with new SVG
            jQueryimg.replaceWith(jQuerysvg);

        }, 'xml');
    });
}); 