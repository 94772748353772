let gmarkers1 = [];
// let markers1 = [];
let infowindow = new google.maps.InfoWindow({
    content: ''
});
let bounds = new google.maps.LatLngBounds();

/**
 * Initialize map when window is loaded
 */
google.maps.event.addDomListener( window, 'load', initialize );
function initialize() {

    // Return null if there's no <div id="map-canvas"></div> found
    if ( null === document.getElementById( "map-canvas" ) ) {
        return;
    }

    let center = new google.maps.LatLng(40.8867779, -73.9061561);
    let mapOptions = {
        zoom: 13,
        // center: center,
        scrollwheel: true,
        styles: [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
    };

    map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

    for (i = 0; i < markers1.length; i++) {
        addMarker(markers1[i]);
    }

    // building = new google.maps.Marker({
    //     position: center,
    //     icon: '/wp-content/themes/3660-waldo/assets/images/3660-waldo-marker.png',
    //     map: map
    // });

    // google.maps.event.addListener(map, 'click', function() {
    //     infowindow.close();
    // });

    map.fitBounds(bounds);
}

/**
 * Function to add marker to map
 */
function addMarker(marker) {
    let index       = marker[0];
    let pos         = new google.maps.LatLng(marker[1], marker[2]);
    let address     = marker[3];
    let title       = marker[4];
    let description = marker[5];
    let image       = marker[6];
    let gallery     = marker[7];
    let shorthand   = marker[8];

    let content = '<div class="location_popup">' +
        '<div class="location_image" style="background-image: url('+image+')"></div> ' +
        '<div class="location_content_wrapper">' +
        '<h5 class="location_title">' + title + '</h5>' +
        '<p class="location_address"><i class="fas fa-map-marker-alt"></i> ' + shorthand + '</p><hr>' +
        '<p class="location_content">' + description + '</p>' +
        '</div>';

    marker1 = new google.maps.Marker({
        title: title,
        position: pos,
        map: map,
        content: '<div class="richmarker-wrapper"></div>',
        shadow: 0,
        icon: '' +
        '' +
        '/slate/wp-content/themes/slatepg/assets/images/map-icon.png',
    });

    gmarkers1.push(marker1);

    bounds.extend(marker1.position);

    // Marker click listener
    google.maps.event.addListener(marker1, 'click', (function (marker1, content) {
        return function () {
            infowindow.setContent(content);
            infowindow.open(map, marker1);
            map.panTo(this.getPosition());
        };
    })(marker1, content));

    // *
    // START INFOWINDOW CUSTOMIZE.
    // The google.maps.event.addListener() event expects
    // the creation of the infowindow HTML structure 'domready'
    // and before the opening of the infowindow, defined styles are applied.
    // *
    google.maps.event.addListener(infowindow, 'domready', function() {

        // Reference to the DIV that wraps the bottom of infowindow
        let iwOuter = jQuery('.gm-style-iw');

        /* Since this div is in a position prior to .gm-div style-iw.
         * We use jQuery and create a iwBackground variable,
         * and took advantage of the existing reference .gm-style-iw for the previous div with .prev().
        */
        let iwBackground = iwOuter.prev();

        // Removes background shadow DIV
        iwBackground.children(':nth-child(2)').css({'display' : 'none'});

        // Removes white background DIV
        iwBackground.children(':nth-child(4)').css({'display' : 'none'});

        // Moves the infowindow 115px to the right.
        // iwOuter.parent().parent().css({left: '115px'});

        // Moves the shadow of the arrow 76px to the left margin.
        iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'left: 76px !important;'; });

        // Moves the arrow 76px to the left margin.
        iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'left: 76px !important;'; });

        // Changes the desired tail shadow color.
        // iwBackground.children(':nth-child(3)').find('div').children().css({'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px', 'z-index' : '1'});

        // Reference to the div that groups the close button elements.
        let iwCloseBtn = iwOuter.next();

        // Apply the desired effect to the close button
        iwCloseBtn.css({opacity: '1', right: '45px', top: '25px'});

        // If the content of infowindow not exceed the set maximum height, then the gradient is removed.
        if(jQuery('.iw-content').height() < 140){
            jQuery('.iw-bottom-gradient').css({display: 'none'});
        }

        // The API automatically applies 0.7 opacity to the button after the mouseout event. This function reverses this event to the desired value.
        iwCloseBtn.mouseout(function(){
            jQuery(this).css({opacity: '1'});
        });
    });
}

/**
 * The function to trigger the marker click, 'id' is the reference index to the 'markers' array.
 *
 * @param id
 */
function openMarker(id) {
    event.preventDefault();
    google.maps.event.trigger(gmarkers1[id], 'click');
}